*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Roobert, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  border-radius: .3125rem;
  padding: .1875em .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

h1 {
  font-size: 1.5rem;
  line-height: 1.25;
}

@media (min-width: 768px) {
  h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

h2 {
  font-size: 1.125rem;
  line-height: 2;
}

@media (min-width: 768px) {
  h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

p {
  opacity: .8;
  line-height: 1.375;
}

.link {
  text-underline-offset: 4px;
  text-decoration-line: underline;
}

.link:hover {
  --tw-text-opacity: 1;
  color: hsl(161 50% 30% / var(--tw-text-opacity));
}

:is(.dark .link):hover {
  --tw-text-opacity: 1;
  color: hsl(161 50% 67% / var(--tw-text-opacity));
}

.button {
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 85% / var(--tw-bg-opacity));
  fill: currentColor;
  --tw-text-opacity: 1;
  color: hsl(156 10% 25% / var(--tw-text-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  gap: .5rem;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.button:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 75% / var(--tw-bg-opacity));
}

:is(.dark .button) {
  --tw-bg-opacity: 1;
  background-color: hsl(157 6% 33% / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(158 6% 80% / var(--tw-text-opacity));
}

:is(.dark .button:hover) {
  --tw-bg-opacity: 1;
  background-color: hsl(157 6% 37% / var(--tw-bg-opacity));
}

a:focus-visible, button:focus-visible {
  outline-offset: 4px;
  outline-width: 2px;
  outline-style: solid;
}

.read-next {
  width: -moz-fit-content;
  width: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 85% / var(--tw-bg-opacity));
  border-radius: .5rem;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
  padding: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-decoration-line: none;
  display: flex;
}

.read-next:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 75% / var(--tw-bg-opacity));
}

:is(.dark .read-next) {
  --tw-bg-opacity: 1;
  background-color: hsl(157 6% 33% / var(--tw-bg-opacity));
}

:is(.dark .read-next):hover {
  --tw-bg-opacity: 1;
  background-color: hsl(157 6% 40% / var(--tw-bg-opacity));
}

.header-nav-link {
  --tw-text-opacity: 1;
  color: hsl(156 10% 25% / var(--tw-text-opacity));
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.header-nav-link:hover {
  --tw-text-opacity: 1;
  color: hsl(157 6% 40% / var(--tw-text-opacity));
}

:is(.dark .header-nav-link) {
  --tw-text-opacity: 1;
  color: hsl(158 6% 80% / var(--tw-text-opacity));
}

.header-theme-toggle {
  --tw-text-opacity: 1;
  color: hsl(156 10% 25% / var(--tw-text-opacity));
  border-radius: .375rem;
  align-items: center;
  gap: .25rem;
  padding: .25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.header-theme-toggle:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 85% / var(--tw-bg-opacity));
}

:is(.dark .header-theme-toggle) {
  --tw-text-opacity: 1;
  color: hsl(158 6% 60% / var(--tw-text-opacity));
}

:is(.dark .header-theme-toggle:hover) {
  --tw-bg-opacity: 1;
  background-color: hsl(158 6% 23% / var(--tw-bg-opacity));
}

.header-theme-toggle svg {
  height: 2rem;
  width: 2rem;
}

html:not(.dark) .header-theme-toggle svg:last-of-type, html.dark .header-theme-toggle svg:first-of-type {
  display: none;
}

.bento-card {
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 100% / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(156 10% 25% / var(--tw-text-opacity));
  border-radius: 1rem;
  padding: 2rem;
}

:is(.dark .bento-card) {
  --tw-bg-opacity: 1;
  background-color: hsl(158 6% 23% / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(158 6% 80% / var(--tw-text-opacity));
}

.bento-project {
  aspect-ratio: 1 / 1;
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 100% / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: hsl(156 10% 25% / var(--tw-text-opacity));
  border-radius: 1rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 2rem;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

:is(.dark .bento-project) {
  --tw-bg-opacity: 1;
  background-color: hsl(158 6% 23% / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(158 6% 80% / var(--tw-text-opacity));
}

.bento-button {
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 85% / var(--tw-bg-opacity));
  fill: currentColor;
  --tw-text-opacity: 1;
  color: hsl(156 10% 25% / var(--tw-text-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  gap: .5rem;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.bento-button:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 75% / var(--tw-bg-opacity));
}

:is(.dark .bento-button) {
  --tw-bg-opacity: 1;
  background-color: hsl(157 6% 33% / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(158 6% 80% / var(--tw-text-opacity));
}

:is(.dark .bento-button:hover) {
  --tw-bg-opacity: 1;
  background-color: hsl(157 6% 37% / var(--tw-bg-opacity));
}

.bento-button {
  height: 100%;
  min-height: 96px;
  border-radius: .75rem;
  text-decoration-line: none;
}

.bento-button svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: #4f5955;
}

:is(.dark .bento-button svg) {
  fill: #f7f6f3;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-2 {
  height: .5rem;
}

.h-32 {
  height: 8rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.w-2 {
  width: .5rem;
}

.w-32 {
  width: 8rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-prose {
  max-width: 65ch;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.self-start {
  align-self: flex-start;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-mushroom300 {
  --tw-border-opacity: 1;
  border-color: hsl(45 21% 85% / var(--tw-border-opacity));
}

.bg-feather {
  --tw-bg-opacity: 1;
  background-color: hsl(161 50% 67% / var(--tw-bg-opacity));
}

.bg-mushroom200 {
  --tw-bg-opacity: 1;
  background-color: hsl(45 21% 96% / var(--tw-bg-opacity));
}

.fill-current {
  fill: currentColor;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-24 {
  padding-top: 6rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-moss700 {
  --tw-text-opacity: 1;
  color: hsl(157 6% 40% / var(--tw-text-opacity));
}

.text-moss900 {
  --tw-text-opacity: 1;
  color: hsl(156 10% 25% / var(--tw-text-opacity));
}

.opacity-40 {
  opacity: .4;
}

.opacity-60 {
  opacity: .6;
}

.opacity-80 {
  opacity: .8;
}

@font-face {
  font-family: Roobert;
  font-display: swap;
  src: url("Roobert-Regular.c85b7ba3.woff2") format("woff2"), url("Roobert-Regular.3df39764.woff") format("woff");
  font-weight: regular;
  font-style: normal;
}

:is(.dark .dark\:prose-invert) {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-kbd: var(--tw-prose-invert-kbd);
  --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:underline-offset-4:hover {
  text-underline-offset: 4px;
}

:is(.dark .dark\:border-leaf) {
  --tw-border-opacity: 1;
  border-color: hsl(158 6% 40% / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-feather) {
  --tw-bg-opacity: 1;
  background-color: hsl(161 50% 67% / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-fern) {
  --tw-bg-opacity: 1;
  background-color: hsl(156 5% 18% / var(--tw-bg-opacity));
}

:is(.dark .dark\:text-clover) {
  --tw-text-opacity: 1;
  color: hsl(158 6% 60% / var(--tw-text-opacity));
}

:is(.dark .dark\:text-mushroom200) {
  --tw-text-opacity: 1;
  color: hsl(45 21% 96% / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:mr-96 {
    margin-right: 24rem;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:mt-auto {
    margin-top: auto;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-1 {
    gap: .25rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }
}

/*# sourceMappingURL=index.14856566.css.map */
